import logo from "./logo.webp"

const clientData = {
    client_entity: 14,
    attorney_firm: 'Serena Flowers Law',
    attorney_name: 'Serena Flowers',
    attorney_number: '(662) 582-6666 ',
    attorney_email: 'serena.clark@gmail.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_flowers_law_office+(720p).mp4',
    mainColor: '#E0E0DB',
    secondaryColor: '#1C75BC',
    siteLink: '',
    logo
}

export default clientData