import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $axios from './plugins/axios'

Vue.config.productionTip = false

/** Vuelidate plugin settings */
import Vuelidate from 'vuelidate'

import vuetify from './plugins/vuetify'

Vue.use(Vuelidate)

/** Axios plugin settings */
Vue.use({
  install(Vue) {
    Vue.prototype.$axios = $axios
  }
});

/** Vue cookies */

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
